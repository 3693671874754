[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    height: rem-calc(50);
}


.form--ghost {
    [type='text'],
    [type='password'],
    [type='date'],
    [type='datetime'],
    [type='datetime-local'],
    [type='month'],
    [type='week'],
    [type='email'],
    [type='number'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='url'],
    [type='color'],
    textarea {
        border: 1px solid $white;
        background-color: transparent;
        color: $white;
    }
}
