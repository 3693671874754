$top-bar-height-mobile: rem-calc(50);
$top-bar-small-offset: 80px;

.top-bar {
    height: $top-bar-height-mobile;
    z-index: 10;
    backface-visibility: hidden;
    transition: background 0.1s ease;
    transform: height 0.2s ease;
    background: transparent;
    margin: 0 auto;
    padding: 0 rem-calc(15);
    max-width: 75rem;
    margin-left: auto;
    margin-right: auto;
    @include breakpoint(large) {
        height: rem-calc(70);
        border: 0;
    }
    @include breakpoint(medium down) {
        padding-right: 0;
    }

    ul {
        background: transparent;
    }

    .menu {
        a:not(.button) {
            color: $black;
            font-size: rem-calc(15);
            margin: rem-calc(0 25);
            padding: rem-calc(18 0);
            vertical-align: middle;
            transition: color 0.15s ease;
            background: transparent;

            &:hover {
                color: $secondary-color;
            }

            &.top-bar__social {
                margin: rem-calc(0 15);
            }
        }

        .button {
            padding: rem-calc(14 20);
            margin-left: rem-calc(10);
        }

        li.is-active {
            a:not(.button) {
                font-weight: bold;
                color: $primary-color;
                background: transparent;
            }

            .button {
                color: $black;
            }
        }
    }


    .top-bar-right {
        display: flex;
    }
}

.top-bar-title {
    margin: 0;
    @include breakpoint(large down) {
        width: 100%;
    }
}

.top-bar-logo {
    width: 166px;
    height: 21px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 166px 21px;
    background-image: url("../img/skdigital_farba.svg");
    display: block;
    @include breakpoint(large) {
        background-size: auto;
    }
}

.top-bar-container {
    position: relative;
    z-index: 10;
    left: 0;
    right: 0;
    background-color: $white;
    transition: box-shadow $global-transition;

    .is-stuck {
        background-color: $white;
        position: fixed;
        box-shadow: 5px 0 20px rgba($black, 0.05);
    }
}

.sticky-topbar {
    background: transparent;
}

.top-bar-toggle {
    float: right;
    height: $top-bar-height-mobile;
    width: $top-bar-height-mobile;
    line-height: rem-calc(32);
    font-size: rem-calc(14);
    text-transform: uppercase;
    color: $light-gray;
    cursor: pointer;
    border-radius: 0;
    @include breakpoint(large) {
        line-height: rem-calc(40);
    }
}

.top-bar {
    .show-for-large {
        @include breakpoint(1250px down) {
            display: none;
        }
    }

    .hide-for-large {
        @include breakpoint(1250px down) {
            display: block !important;
        }
    }
}
/*
|--------------------------------------------------------------------------
| Off canvas
|--------------------------------------------------------------------------
*/
.off-canvas {
    color: $white;
    padding: rem-calc(60 20 30 20);
    font-size: rem-calc(16);

    // button
    .button {
        &.black {
          background-color: $white;
          color: $black;

          &:focus,
          &:hover {
            background-color: darken($white, 10);
          }
        }
    }

    &.is-transition-overlap {
        z-index: 9999;
    }

    a {
        color: $white;
        text-align: center;

        img {
            margin: rem-calc(0 10);
        }
    }

    .menu:not(.horizontal) {
        display: block;
        margin-bottom: rem-calc(20);
        margin-top: rem-calc(20);

        li {
            display: block; //border-bottom: 1px solid rgba($color: $white, $alpha: .1)
        }

        .is-active > a {
            background-color: transparent;
            color: $secondary-color;
        }

        a {
            outline: none;
            padding: rem-calc(20 0);

            &:focus,
            &:hover {
                text-decoration: underline;
            }
        }

        .button {
            width: 100%;
            padding: rem-calc(20 20);
        }
    }
    .contact{
        text-align: center;
        position: absolute;
        bottom: rem-calc(50);
        left: 0;
        right: 0;
        color: #686868;
        a{
            padding: rem-calc(5);
        }
        .menu{
            margin-top: rem-calc(20);
        }
    }

    .close-button {
        right: 20px;
        top: 10px;
        font-size: 3em;
        color: $white;
        opacity: 0.5;
    }
}
/*
|--------------------------------------------------------------------------
| Other
|--------------------------------------------------------------------------
*/
.hamburger {
    @include hamburger($color: $black, $color-hover: $black, $height: 12px);
    position: relative;
    top: -1px;
}
