

table {
    @include breakpoint(medium) {
        font-size: rem-calc(18);
        font-weight: 500;
    }

    &.transparent {
        border: 0;

        tbody,
        tfoot,
        thead {
            background-color: transparent;
            border-left: 0;
            border-right: 0;
        }

        thead {
            border-top: 0;
            background-color: rgba(#D8D8D8, 0.05);
            border-bottom: 2px solid #636363;
        }
    }

    tbody,
    tfoot,
    thead {
        border-color: #636363;

        tr {
            border-bottom: 1px solid #636363;

            td,
            th {
                padding: rem-calc(20 20);
                @include breakpoint(medium) {
                    padding: rem-calc(20 30);
                }
            }
        }
    }

    thead {
        border-top: 0;
        background-color: rgba(#D8D8D8, 0.05);
        border-bottom: 2px solid #636363;
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: rgba(#D8D8D8, 0.05);
                border-bottom: 1px solid #636363;
            }
        }
    }

    .mobile-title{
        display: none;
        visibility: hidden;
        @include breakpoint(small only) {
            display: inline-block;
            visibility: visible;
            margin-right: rem-calc(10);
        }
    }
}

.bg-gray {
    color: $white;

    table {
        thead {
            color: $white;
        }
    }

    a {
        color: $white;
        text-decoration: underline;
    }
}

.table--findings{
    @include breakpoint(small only) {
        tr{
            border-bottom: 3px solid #636363!important;
            td{
                &:first-child{
                    border-bottom: 1px solid #636363;
                }
                &:not(:first-child){
                    display: inline-block;
                }
            }
        }
    }
}

.tag {
    @include breakpoint(medium) {
        font-size: rem-calc(18);
        font-weight: 500;
        margin-right: rem-calc(10);
    }

    &:before {
        content: '';
        width: rem-calc(13);
        height: rem-calc(13);
        border-radius: 100%;
        display: inline-block;
        margin-right: rem-calc(10);
    }

    &.sucess {
        color: $success-color;

        &:before {
            background-color: $success-color;
        }
    }

    &.alert {
        color: $alert-color;

        &:before {
            background-color: $alert-color;
        }
    }

    &.warning {
        color: $warning-color;

        &:before {
            background-color: $warning-color;
        }
    }

    .tag__content{
        @include breakpoint(small only) {
            display: none;
            visibility: hidden;
        }
    }
}
