.card {
    flex: 0 0 auto;
    transition: background $global-transition;

    .card-section {
        padding: rem-calc(40);
    }
}

.card--chapters {
    text-align: center;

    &:hover {
        background-color: #F6F6F6;
    }

    .card-section {
        padding: rem-calc(30 20 0 20);
        @include breakpoint(medium) {
            padding: rem-calc(40 40 0 40);
        }

        .img-wrapper {
            margin-bottom: rem-calc(20);
            height: rem-calc(125);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .headline {

            @include breakpoint(medium) {
                min-height: rem-calc(48);
            }
        }
    }

    .card-footer {
        padding: rem-calc(15 40 30 40);
        @include breakpoint(medium) {
            padding: rem-calc(15 40 30 20);
        }

        .img-wrapper {
            height: rem-calc(25);

            img {
                margin-bottom: 0;
            }
        }
    }
}
