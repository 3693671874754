body,
html {
    overflow-x: hidden;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../fonts/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.section {
    padding: rem-calc(50 0);
    @include breakpoint(medium) {
        padding: rem-calc(80 0);
    }

    .sk-digital-icon {
        display: block;
        margin: 0 auto rem-calc(30);
    }

    .section__title {
        margin-bottom: rem-calc(40);
    }
}

.scroll-down {
    margin-top: rem-calc(40);
    display: block;
}

.bg-gray {
    background-color: $dark-gray;
    color: $white;
}

.section--stats {
    .grid-container {
        margin-bottom: rem-calc(80);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .lead {
        @include breakpoint(medium) {
            font-size: rem-calc(27);
            line-height: rem-calc(40);
        }
    }
}

.block-steps {}

.steps-accordion {
    list-style: none;
    padding: 0;
    margin: 0;

    .accordion-item {
        margin: rem-calc(35 0);
        padding-bottom: rem-calc(35);
        padding-top: 0;
        border-bottom: 2px solid #232121;
        @include breakpoint(medium) {
            @include xy-grid;
            margin-left: -0.9375rem;
            margin-right: -0.9375rem;
            display: flex;
            padding-top: rem-calc(0);
            padding-bottom: rem-calc(35);
        }

        &:not(.is-active) {
            .accordion-content {
                @include breakpoint(small only) {
                    display: none;
                }
            }
        }

        &.is-active {
            .accordion-title:before {
                transform: rotate(180deg);
            }
        }

        &:last-child {
            margin-bottom: 0;
            border: 0;
        }

        .accordion-title {
            text-decoration: none;
            font-weight: 500;
            @include breakpoint(small only) {
                margin-bottom: rem-calc(0);
                display: block;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: rem-calc(20);
                    width: rem-calc(25);
                    height: rem-calc(25);
                    background-size: auto;
                    background-image: url("../img/icons/icon-caret.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    transition: transform $global-transition;
                }
            }
            @include breakpoint(medium) {
                @include xy-cell(1 of 3);
            }
            @include breakpoint(large) {
                font-size: rem-calc(50);
            }

            span {
                display: inline-block;
                margin-right: rem-calc(10);
                @include breakpoint(medium) {
                    margin-right: rem-calc(40);
                }
            }
        }

        .accordion-content {
            @include breakpoint(medium) {
                @include xy-cell(2 of 3);
            }
            @include breakpoint(small only) {
                padding-top: rem-calc(20);
            }

            ul {
                font-size: rem-calc(18);
                @include breakpoint(medium) {
                    font-size: rem-calc(20);
                }

                li {
                    margin-bottom: rem-calc(4);
                }
            }
        }
    }
}

.footer {
    padding: rem-calc(80 0 30 0);
    border-top: 1px solid #3D3D3D;

    &__logo {
        margin-bottom: rem-calc(20);
    }

    hr {
        width: 100%;
        max-width: none;
        border-color: #3D3D3D;
        margin: rem-calc(30 0);
    }

    .grid-container:first-child {
        margin-bottom: rem-calc(70);
        @include breakpoint(small only) {
            text-align: center;
            margin-bottom: 0;

            & > .grid-x > .cell {
                margin-bottom: rem-calc(40);

                .h1 {
                    margin-bottom: rem-calc(20);
                }
            }
        }
    }

    form {
        color: #686868;

        .button {
            width: 100%;
        }

        a {
            color: #686868;
            text-decoration: underline;
            transition: color $global-transition;

            &:hover {
                color: $white;
            }
        }

        .privacy-policy {
            @include breakpoint(large) {
                width: calc(75%);
            }
        }
    }

    .menu {
        margin-top: rem-calc(15);

        a {
            padding: rem-calc(6);
        }
    }

    .copyright {
        color: #B4B4B4;
    }
}

.fit-picture {
    height: 100px;
    padding: 10px;
    @include breakpoint(370px down) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.copyright-text {
    font-size: medium !important;
    @include breakpoint(1024px) {
        margin-top: 2.8% !important;
    }
}

/* width */
::-webkit-scrollbar {
    width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
    background: $white;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: $dark-gray;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
