.carousel {
    outline: none;
    overflow: visible;
    position: relative;
    cursor: move;
    @include breakpoint(small only) {
        overflow: visible;
    }

    .carousel-cell {
        width: 100%;
        outline: none;
    }

    .flickity-page-dots {
        @include breakpoint(large) {
            display: none;
        }
    }
}

.carousel--chapters {
    .carousel-cell {
        width: 80%;
        margin: rem-calc(0 20);
        opacity: 0.5;
        transition: opacity $global-transition;
        @include breakpoint(medium) {
            border-right: 2px solid #232121;
            width: 60%;
            min-height: rem-calc(500);
            padding: rem-calc(20 30 20 0);
        }
        @include breakpoint(large) {
            min-height: rem-calc(600);
            width: 40%;
            margin: rem-calc(0 50);
            padding: rem-calc(20 50 20 0);
        }

        &:last-child {
            border-color: transparent;
        }

        &.is-selected {
            opacity: 1;
        }

        img {
            margin-bottom: rem-calc(30);
        }

        .headline {
            @include breakpoint(medium) {
                margin-bottom: rem-calc(50);
            }
        }
    }
}

.flickity-page-dots {
    list-style: none;
    margin: 0 auto;
    text-align: center;
    margin-top: rem-calc(20);
    margin-bottom: rem-calc(-30);
    display: inline-block;
    position: relative;
    left: 50%;
    top: rem-calc(15);
    transform: translateX(-50%);

    &:before {
        content: '';
        height: rem-calc(2);
        background-color: #232121;
        position: absolute;
        top: 13px;
        left: 20px;
        right: 20px;
    }

    li {
        position: relative;
        width: rem-calc(40);
        height: rem-calc(8);
        display: inline-block;
        margin: rem-calc(0 5);
        cursor: pointer;
        padding: rem-calc(10 0);

        &:before {
            content: '';
            position: absolute;
            width: rem-calc(8);
            height: rem-calc(8);
            left: 50%;
            margin-left: -4px;
            background: $white;
            opacity: 0.3;
            border-radius: 100%;
            transition: transform $global-transition;
        }

        &.is-selected,
        &:hover {
            &:before {
                opacity: 1;
                transform: scale(1.4);
            }
        }
    }
}

.flickity-button {
    display: none!important;
}
/*
 |--------------------------------------------------------------------------
 | Carousel elements
 |--------------------------------------------------------------------------
 */
.carousel-wrapper {
    position: relative;
}

.carousel-buttons {
    list-style: none;
    cursor: default;
    color: $black;
    margin: 0;
    @include breakpoint(medium down) {
        display: none;
    }

    li {
        &.carousel-next,
        &.carousel-prev {
            width: rem-calc(80);
            height: rem-calc(50);
            transition: transform $global-transition, box-shadow $global-transition;
            background-color: $white;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 10px 30px 0 rgba(0,0,0,0.06);

            &.carousel-prev {
                &:hover {
                    transform: translateX(-6px);
                    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.13);
                }
            }

            &.carousel-next {
                &:hover {
                    transform: translateX(6px);
                    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.13);
                }
            }
        }
    }

    &.absolute {
        li {
            &.carousel-next {
                left: auto;
                right: -120px;
            }

            &.carousel-prev {
                left: -120px;
                right: auto;
            }
        }

        .carousel-next,
        .carousel-prev {
            position: absolute;
            top: 50%;
            margin-top: rem-calc(-40);
        }
    }
}
/*
 |--------------------------------------------------------------------------
 | FOUC prevention
 |--------------------------------------------------------------------------
 */
.carousel {
    opacity: 0;
    transition: opacity 0.4s;

    &.is-hidden {
        display: none;
    }

    &.flickity-enabled {
        opacity: 1;
    }
}
