p {
    margin-bottom: rem-calc(25);

}
.lead{
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    @include breakpoint(medium) {
        font-size: rem-calc(20);
        line-height: rem-calc(32);
    }
}

small {
    font-size: rem-calc(13);
    line-height: rem-calc(19);
    display: inline-block;
}

address{
    font-style: normal;
}
