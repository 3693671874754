.button {
    padding: rem-calc(16 25);
    font-size: rem-calc(16);
    @include breakpoint(medium) {
        min-width: rem-calc(150);
    }

    &.ghost {
        border: 1px solid $dark-gray;
        color: $dark-gray;
        background-color: transparent;

        &:focus,
        &:hover {
            color: $white;
            background-color: $dark-gray;
        }
    }

    &.white {
        background-color: $white;
        color: $black;

        &:focus,
        &:hover {
            background-color: darken($white, 10);
        }
    }
    &.black {
        background-color: #2C2C2C;
        color: $white;

        &:focus,
        &:hover {
            background-color: darken(#2C2C2C, 10);
        }
    }
}

.link-external {
    display: inline-block;
    font-weight: 500;


    &:after {
        content: '';
        background-image: url("../img/icons/icon-external.svg");
        width: 14px;
        height: 14px;
        display: inline-block;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        margin-left: rem-calc(10);
    }
}
