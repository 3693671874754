/* Style The Dropdown Button */
//.drop-btn {
//  background-color: #4caf50;
//  color: white;
//  padding: 16px;
//  font-size: 16px;
//  border: none;
//  cursor: pointer;
//}

/* The container <li> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content <ul> (Hidden by Default) */
.dropdown-content {
  list-style: none;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown li items on hover */
//.dropdown-content li:hover {
//  background-color: darken($white, 4);
//  color: $black;
//}

.dropdown-content li:hover ~ a {
  color: $black;
}

.dropdown-content a:hover {
  color: $black;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  background-color: $white;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
//.dropdown:hover .drop-btn {
//  background-color: #3e8e41;
//}

/* Custom <a> padding for _top-bar.scss component */
.off-canvas {
  .menu:not(.horizontal) {
    .dropdown-content a {
      padding: 20px;
    }

    .dropdown-content > li {
      &:focus,
      &:hover {
        background-color: darken($white, 10);
      }
    }
  }
}